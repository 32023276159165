import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"


// import fbWhite from "../images/facebook-icon.svg";
import Telephone from "../images/phone-solid.svg";
import Email from "../images/envelope-solid.svg";
import Marker from "../images/map-marker-alt-solid.svg";

import "./Footer.css";
import { Fragment } from 'react';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
    },
    overlay: {
        backgroundColor: "#212121bf"
    },
    portal: {
        zIndex: "99999",
        position: "relative"
    }
};

function Footer(props) {

    const [appointmentModal, setAppointmentModal] = useState(false);

    const data = props.footerMenu;
    console.log(props)

    return (
        <footer className="footer" style={{ backgroundColor: "#101242" }}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6  py-20 container m-auto px-4 md:px-0">
                <div>
                    <div>
                        <Link to={`/`} className="flex items-center">
                            <StaticImage src="../images/logo-light.png" loading="lazy" placeholder="none" alt="white-logo" height={160} />
                        </Link>
                    </div>
                    <div style={{ maxWidth: "295px", color: "#c0c1ce" }}>
                        <p className="pt-8">
                            {data.footerInformationTemplate.footerContent}
                        </p>
                    </div>
                    <div className="pt-8" style={{ color: "#c0c1ce" }}>
                        <div className="flex">
                            <div>
                                <img src={Marker} alt="map-marker" width={15} />
                            </div>
                            <a href={data.footerInformationTemplate.address.addressGoogleLink} target="_blank" className="pl-4">{data.footerInformationTemplate.address.addressLabel}</a>
                        </div>
                        <div className="flex pt-4">
                            <div>
                                <img src={Telephone} alt="telephone" width={15} />
                            </div>
                            <a href={`tel:${data.footerInformationTemplate.telephoneNumber}`} className="pl-4">{data.footerInformationTemplate.telephoneNumber}</a>
                        </div>
                        <div className="flex pt-4">
                            <div>
                                <img src={Email} alt="email" width={15} />
                            </div>
                            <a href={`mailto:${data.footerInformationTemplate.supportEmail}`} className="pl-4">{data.footerInformationTemplate.supportEmail}</a>
                        </div>
                    </div>
                </div>
                <div className=" pl-0 md:pl-16">
                    <h2 className="ss-side-title text-white" style={{ fontSize: "24px" }}>{data.footerInformationTemplate.generalPagesTitle}</h2>
                    <ul style={{ color: "#c0c1ce" }}>
                        {props?.headerMenu?.menuAcf?.menu && props.headerMenu.menuAcf.menu.map((tab, i) => (
                            <li key={`general-page-${i}`} className={`${tab.menuGroup.url === "#" ? "pt-0" : "pt-4"}`}>
                                {tab.menuGroup.url === "#"
                                    ?
                                    <span className="w-full hidden">
                                        <div className="flex items-center justify-between w-full">
                                            {tab.menuGroup.label}
                                        </div>
                                    </span>
                                    :
                                    (tab.menuGroup.url.includes("https")
                                        ?
                                        <a href={`${tab.menuGroup.url}/`}>
                                            {tab.menuGroup.label}
                                        </a>
                                        :
                                        (tab.menuGroup.url === "/"
                                            ?
                                            <Link to={`/`}>
                                                {tab.menuGroup.label}
                                            </Link>
                                            :
                                            <Link to={`/${tab.menuGroup.url.replace("http://", "")}/`} className="w-full">
                                                <div className="flex items-center justify-between w-full">
                                                    {tab.menuGroup.label}
                                                </div>
                                            </Link>
                                        )
                                    )
                                }
                            </li>
                        ))}
                        <li className="pt-4">
                            <Link to={`/oroi-kai-proipotheseis/`}>
                                Όροι & Προϋποθέσεις
                                </Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 className="ss-side-title text-white" style={{ fontSize: "24px" }}>{data.footerInformationTemplate.servicesTitle}</h2>
                    <ul style={{ color: "#c0c1ce" }}>
                        {props?.services?.edges && props.services.edges.map((sc, j) => (
                            <li key={`service-f-${j}`} className="pt-4">
                                <Link to={`/ypiresies/${sc.node.slug}/`}>
                                    {sc.node.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div>
                <div className="flex justify-center pb-8 text-white">
                    <a href="https://w3vitals.com" target="_blank" rel="noopener">
                        <img src="https://admin.w3vitals.com/wp-content/uploads/2020/11/w3vitals-logo-e1610190879561.png" alt="w3vitals logo" width="50px" />
                    </a>
                    <div className="pl-4 flex items-end">
                        &copy;{new Date().getFullYear()}
                        <a href="https://w3vitals.com" target="_blank" rel="noopener" className="pl-2">
                            Κατασκευή ιστοσελίδας W3Vitals
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;