
import React from "react"
import HeaderMenu from "./Header"
import FooterMenu from "./Footer"
import { Helmet } from "react-helmet"
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import { StickyContainer } from 'react-sticky';
import LazyHydrate from "react-lazy-hydration";



const Layout = ({ children, header, footer, services, productCategories, metaData }) => (
    <StickyContainer>

        <div>
            {metaData && (
                <Helmet>
                    <html lang={"el"} />
                    <title>{metaData.title}</title>
                    <meta name="description" content={metaData.description} />
                    <link rel="canonical" href={metaData.canonical} />
                    <meta
                        name="viewport"
                        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                    />

                    {(metaData.noIndex && metaData.noIndex === "noindex") && (
                        <meta name="robots" content="noindex" />
                    )}

                    <meta property="og:title" content={metaData.fbTitle} />
                    <meta property="og:description" content={metaData.fbDescription} />
                    <meta property="og:url" content={metaData.canonical} />
                    <meta property="og:type" content={metaData.fbType} />
                    <meta property="og:locale" content={metaData.locale} />
                    <meta property="og:image" content={metaData.image} />
                    <meta property="og:image:width" content="598" />
                    <meta property="og:image:height" content="399" />

                </Helmet>
            )}

            <HeaderMenu headerMenu={header} footerMenu={footer} services={services} productCategories={productCategories} />
            {children}
            <ScrollUpButton
                style={{ backgroundColor: "#DB3914", borderColor: "#DB3914", fill: "#ffffff", outline: "none", zIndex: 9 }}
            />
            <LazyHydrate whenVisible>
                <FooterMenu footerMenu={footer} headerMenu={header} services={services} />
            </LazyHydrate>

        </div>
    </StickyContainer>
)

export default Layout
