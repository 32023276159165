import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'gatsby'

import { pageView } from "../utils/fbApi"
import { StaticImage } from "gatsby-plugin-image"
import Marker from "../images/map-marker-alt-solid-white.svg";
import Telephone from "../images/phone-solid-white.svg";
import fbWhite from "../images/facebook-f-brands.svg";
import instaWhite from "../images/instagram-icon.svg";


// import Cookies from 'js-cookie';

// import sortDown from "../images/chevron-down-solid.svg";
import arrowLeft from "../images/long-arrow-alt-right-solid.svg"
import phoneIcon from "../images/phone-alt-solid.svg"

import { Sticky } from 'react-sticky';

import "./Header.css";

function Header(props) {

    const [menuState, setMenuState] = useState(false);
    let menu = props.headerMenu ? props.headerMenu.menuAcf.menu : [];
    let productCategories = props.productCategories ? props.productCategories : [];
    const footer = props.footerMenu;


    useEffect(() => {
        const queryString = window.location.search;
        const parameters = new URLSearchParams(queryString);
        // const fbclid = parameters.get('fbclid');

        // if (fbclid) {
        //     localStorage.setItem('fbclid', fbclid)
        // }

        // let fbId = localStorage.getItem("fbclid")

        // if the item doesn't exist, return null
        if (window.evId) {
            pageView(window.evId)
        }

    }, [])

    useEffect(() => {
        let menu = document.getElementsByClassName("mobile-menu");
        if (menu[0].style.top === "-375px") {
            menu[0].style.top = "64px"
        } else {
            menu[0].style.top = "-375px"
        }
    }, [menuState])


    function toggleMenu() {
        setMenuState(!menuState)
    }

    function expandMenu(e) {
        e.preventDefault();
    }

    function createSubMenu(subs, parentLabel, distanceFromTop) {
        if (parentLabel !== "Υπηρεσιες" && parentLabel !== "Services" && parentLabel !== "Προιόντα") {
            return (
                <ul className={"top80"}>
                    {subs && subs.map((subLink, index) => (
                        <li key={`sublink-${index}`} >
                            {subLink.url && subLink.url.includes("https://")
                                ?
                                <a href={`/${subLink.url}/`} className="menu-g-link">
                                    {subLink.label}
                                </a>
                                : (
                                    subLink.url
                                        ?
                                        <Link to={`/${subLink.url.replace("http://", "")}/`} className="menu-g-link">
                                            {subLink.label}
                                        </Link>
                                        :
                                        <div>

                                        </div>
                                )

                            }
                        </li>
                    ))}
                </ul>
            )
        } else if (parentLabel == "Υπηρεσιες" || parentLabel == "Services") {
            return (
                <ul className={"top80"} >
                    {(props.services && props.services.edges) && props.services.edges.map((subLink, index) => (
                        <li key={`sublink-${index}`} >
                            <Link to={`/ypiresies/${subLink.node.slug}/`} className="menu-g-link">
                                {subLink.node.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            )
        } else if (parentLabel == "Προιόντα") {
            return (
                <ul className={"top80"} >
                    {(productCategories && productCategories.edges) && productCategories.edges.map((subLink, index) => (
                        <li key={`sublink-${index}`} >
                            <Link to={`/${subLink.node.slug}/`} className="menu-g-link">
                                {subLink.node.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            )
        }
    }

    return (
        <div className="nav-container mx-auto flex md:block justify-center" id="menuId">
            <div className="preheader hidden md:flex px-8 mx-auto">
                <div className="hidden md:flex justify-between m-auto w-full">
                    <div className="flex items-center">
                        <div className="flex">
                            <div>
                                <img src={Marker} alt="map-marker" width={15} />
                            </div>
                            <a href={footer.footerInformationTemplate.address.addressGoogleLink} target="_blank" className="pl-2">{footer.footerInformationTemplate.address.addressLabel}</a>
                        </div>
                        <div className="flex pl-4">
                            <div>
                                <img src={Telephone} alt="telephone" width={15} />
                            </div>
                            <a href={`tel:${footer.footerInformationTemplate.telephoneNumber}`} className="pl-2">{footer.footerInformationTemplate.telephoneNumber}</a>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div>
                            Ακολουθήστε μας:
                        </div>
                        <div className="pl-2 flex">
                            <a href="https://www.facebook.com/KleidarasVarvaridis" target="_blank" rel="noopener">
                                <img src={fbWhite} alt="fbWhite" width={10} />
                            </a>
                            <a href="https://www.instagram.com/kleidaras.varvaridis" target="_blank" rel="noopener" className="pl-2">
                                <img src={instaWhite} alt="fbWhite" width={20} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Sticky topOffset={500} style={{ width: "100%" }}>
                {({
                    style,
                    // the following are also available but unused in this example
                    isSticky,
                    wasSticky,
                    distanceFromTop,
                    distanceFromBottom,
                    calculatedHeight
                }) => (
                    <div className="bg-white m-sticky z-20 w-full" style={style}>

                        <nav className="flex flex-row w-full md:justify-between px-4 md:px-8 mx-auto z-20 bg-white items-center" style={{ minHeight: "64px" }}>
                            <a
                                style={{
                                    marginRight: "20px",
                                    textAlign: "center",
                                    height: "38px",
                                    width: "55px",
                                    lineHeight: "60px",
                                    backgroundColor: "#db3914",
                                    borderRadius: "50%",
                                }}
                                href='tel:2310600600'
                                className="flex justify-center items-center md:hidden"
                            >
                                <img src={phoneIcon} alt="τηλέφωνο" width={18} />
                            </a>
                            <div className="flex flex-row justify-center  md:justify-between w-full md:w-auto relative">
                                <div id="mainMenu" className="block md:hidden"></div>
                                <Link to={`/`} className="flex items-center">
                                    <StaticImage src="../images/main-logo.png" loading="eager" placeholder="none" alt="white-logo" height={60} className={"logo-mob"} />
                                </Link>
                            </div>

                            <button
                                className={!menuState ? 'navbar-toggler navbar-toggler-right collapsed block md:hidden' : 'navbar-toggler navbar-toggler-right block md:hidden'}
                                type="button"
                                aria-label="Toggle navigation"
                                onClick={() => toggleMenu()}
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className="flex items-center text-xl">

                                <ul className="mobile-menu nav">
                                    {menu.map((tab, index) => {
                                        return (
                                            <li key={`hMenu-${index}`} className="pr-5">
                                                {tab.menuGroup.url === "#"
                                                    ?
                                                    <span className="w-full">
                                                        <div className="flex items-center justify-between w-full">
                                                            {tab.menuGroup.label}
                                                            {tab.menuGroup.isnested && (
                                                                <button onClick={(e) => expandMenu(e)} className="block md:hidden ml-8 expand-cross">
                                                                    +
                                                                </button>
                                                            )}
                                                        </div>
                                                    </span>
                                                    :
                                                    (tab.menuGroup.url.includes("https")
                                                        ?
                                                        <a href={`${tab.menuGroup.url}/`}>
                                                            {tab.menuGroup.label}
                                                        </a>
                                                        :
                                                        (tab.menuGroup.url === "/"
                                                            ?
                                                            <Link to={`/`}>
                                                                {tab.menuGroup.label}
                                                            </Link>
                                                            :
                                                            <Link to={`/${tab.menuGroup.url.replace("http://", "")}/`} className="w-full">
                                                                <div className="flex items-center justify-between w-full">
                                                                    {tab.menuGroup.label}
                                                                    {tab.menuGroup.isnested && (
                                                                        <button onClick={(e) => expandMenu(e)} className="block md:hidden ml-8 expand-cross">
                                                                            +
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </Link>
                                                        )

                                                    )

                                                }
                                                {tab.menuGroup.isnested && (
                                                    createSubMenu(tab.menuGroup.childnodes, tab.menuGroup.label, distanceFromTop)
                                                )}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className="hidden md:flex items-center text-xl">
                                <Link to="/epikoinonia/" className="h-appointment-btn flex justify-between items-center">
                                    Κλείστε ραντεβού
                                    <img src={arrowLeft} width={14} alt="βέλος δεξιά" className="ml-2" />
                                </Link>
                            </div>
                        </nav>
                    </div>
                )}
            </Sticky>
        </div>
    )
}

export default Header;